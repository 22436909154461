/* styles defined for indivisual building blocks goes here */

/* input */
/* removes browser auto fill color */
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-background-clip: text;
  transition: background-color 5000s ease-in-out 0s;
}

/* headers start */
.header-1 {
  @apply text-3xl font-extrabold text-gray-900 dark:text-gray-200;
}
.header-2 {
  @apply text-2xl font-bold text-black dark:text-gray-200;
}
.header-3 {
  @apply text-2xl font-semibold text-black dark:text-gray-200;
}
.header-4 {
  @apply text-xl font-semibold text-black dark:text-gray-100;
}
.header-5 {
  @apply text-lg font-semibold text-gray-800 dark:text-gray-300;
}
.header-gray {
  @apply text-sm leading-4 font-medium text-gray-600 dark:text-gray-500 uppercase tracking-wider;
}
/* headers end */

/* links start */
.link-gray {
  @apply text-base text-gray-700 dark:text-gray-400 hover:text-gray-900 dark:hover:text-gray-500 focus:text-gray-900 dark:focus:text-gray-500 leading-6 font-medium transition ease-in-out duration-150;
}
.link-blue {
  @apply font-medium text-blue-theme hover:text-blue-500;
}
/* links end */

/* button start */
.btn {
  @apply cursor-pointer whitespace-nowrap inline-flex items-center justify-center px-3  md:px-4 py-1 md:py-2 border border-transparent text-base  leading-6 font-medium rounded-md  transition ease-in-out duration-150;
}
.btn:disabled {
  @apply opacity-50 cursor-not-allowed;
}
.btn-blue {
  @apply bg-blue-theme text-white hover:bg-blue-700;
}
.btn-red {
  @apply bg-red-600 text-white hover:bg-red-700;
}
.btn-white-outline {
  @apply border bg-white dark:bg-black border-gray-300 dark:border-gray-700 text-gray-700 dark:text-gray-400 hover:text-gray-600 dark:hover:text-gray-300 focus:text-gray-600 dark:focus:text-gray-300;
}
.btn-blue-outline {
  @apply border bg-white dark:bg-black border-blue-500 dark:border-blue-500 text-blue-theme dark:text-blue-theme hover:text-blue-700 dark:hover:text-blue-400 focus:text-blue-700 dark:focus:text-blue-400;
}
.btn-icon {
  @apply p-2 rounded-full  text-gray-600 dark:text-gray-300 hover:text-gray-800 dark:hover:text-gray-300 hover:bg-blue-50 dark:hover:bg-gray-800;
}
.btn-dark-gray {
  @apply bg-gray-700 dark:bg-blue-500 text-white hover:bg-gray-800 dark:hover:bg-blue-700 overflow-hidden rounded-lg p-2 cursor-pointer border dark:border-gray-700 focus:outline-none  focus:border-blue-500 text-sm;
}
.button-click {
  /* ADDS CLICKY EXPERIENCE TO BUTTON */
  @apply transform hover:scale-105 active:scale-95;
}
/* buttons end */

/* textarea start */
.textarea-nowrap {
  white-space: pre;
  overflow-wrap: normal;
  overflow-x: scroll;
}
.textarea-prewrap {
  white-space: pre-wrap;
  overflow: visible;
  overflow-x: scroll;
}
.textarea-transparent {
  border: none;
  resize: none;
  box-shadow: none;
  padding: 0;
}
.textarea-transparent:focus {
  border: none;
  box-shadow: none;
}
/* textarea end */

/* .border-primary {
  @apply border-gray-300 dark:border-gray-800;
} */

/* divide */

.divide-primary {
  @apply divide-gray-300 dark:divide-gray-800;
}

/*
this is the resize icon for widget edit.
this is a pseudo class
maintain dark/light mode agnostic color
*/
.react-grid-item > .react-resizable-handle::after {
  border-right: 2px solid rgba(208, 203, 203, 0.4) !important;
  border-bottom: 2px solid rgba(208, 203, 203, 0.4) !important;
}

.scrollbar-stable {
  scrollbar-gutter: stable;
}

.break-word {
  /* NOTE: 
    long word     👉🏻 break from anywhere
    long sentense 👉🏻 break from spaces 
  */
  word-break: break-word;
}
