@layer base {
  * {
    @apply border-border break-words;
  }
  body {
    @apply bg-background text-foreground;
  }
}

/* scrollbar start */
*::-webkit-scrollbar {
  @apply w-2 h-2 /*height 1 for horizontal scroll */;
}
*::-webkit-scrollbar-track {
  @apply bg-transparent;
}
*::-webkit-scrollbar-thumb {
  @apply bg-zinc-200/50 dark:bg-zinc-900 bg-opacity-75 backdrop-blur backdrop-filter rounded-lg bg-clip-padding;
}
*::-webkit-scrollbar-thumb:hover {
  @apply bg-zinc-300/70 dark:bg-zinc-900 bg-opacity-75 backdrop-blur backdrop-filter;
}
::-webkit-scrollbar-corner {
  background: rgba(0, 0, 0, 0);
}

/* scrollbar end */

/* hide tooltip is trigger element is not in viewport */
[data-popper-reference-hidden="true"] {
  display: none;
}

/* Range knob start */
input[type="range"]::-webkit-slider-thumb {
  -webkit-appearance: none;
  background-color: var(--foreground);
  width: 20px;
  height: 20px;
  border-radius: 50%;
}

input[type="range"]::-moz-range-thumb {
  background-color: var(--foreground);
  width: 20px;
  height: 20px;
  border-radius: 50%;
}
/* Range knob end */



@media print {
  @page {
    /*size: A4 portrait;*/
    size: 330mm 427mm;
    margin: 0mm;
  }
  svg {
    max-width: 95vw;
  }
  body {
    -webkit-print-color-adjust: exact;
    -ms-zoom: 1.665;
    margin: 10px;
  }

  h1,
  h2,
  h3,
  h4,
  h5 {
    page-break-after: avoid;
  }

  thead {
    display: table-header-group;
  }
  tfoot {
    display: table-header-group;
  }
  .break-inside-avoid {
    break-inside: avoid;
  }
  .break-after-avoid {
    break-after: avoid;
  }
  .break-before-always {
    break-before: always;
  }
}
