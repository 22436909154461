/* styles defined for resuable components goes here */

.tag-default {
  /* TAG ESSENTIAL STYLES */
  @apply inline-flex items-center  px-2 py-1 rounded-md leading-5 break-all;
}
/* menu start */
.menu-items {
  @apply absolute origin-top-right bg-background text-foreground border rounded-md shadow-elevate1 outline-none;
}
.menu-item {
  @apply flex justify-between w-full px-4 py-2 text-sm leading-5 text-left;
}
.menu-item-active {
  @apply bg-gray-100 dark:bg-black text-gray-900 dark:text-gray-300 cursor-pointer;
}
.menu-item-inactive {
  @apply text-gray-700 dark:text-gray-400;
}
.menu-button {
  @apply inline-flex justify-center w-full px-4 py-2 text-sm font-medium leading-5 text-gray-700 dark:text-gray-300 transition duration-150 ease-in-out bg-background border rounded-md;
}
.menu-button:hover {
  @apply text-gray-800 dark:text-gray-100;
}
.menu-button:focus {
  @apply outline-none;
}
.menu-button:active {
  @apply bg-gray-100 dark:bg-gray-900 text-gray-800 dark:text-gray-300;
}
/* menu end */

/* Table */

.table-row > th:last-child {
  @apply border-r-0;
}

.table-body > tr:last-child > td {
  @apply border-b-0;
}

/* loader start */
.loader {
  border: 3px solid rgb(182, 198, 230);
  border-top-color: rgb(79 70 229);
}
/* loader end */

/* listbox/combobox options */
.list-option {
  @apply p-1 max-h-60 overflow-auto rounded-md bg-background text-foreground border ring-black focus:outline-none sm:text-sm animate-slideDown z-50;
}

.list-button {
  @apply bg-background text-foreground rounded-md border relative text-left cursor-pointer text-sm overflow-hidden;
}

/* SIDEPANEL */
.pullButtonLeft {
  @apply absolute 
          cursor-pointer 
          p-[0.25rem]
          rounded-tr-xl 
          rounded-br-xl 
          w-[40px]
          flex 
          justify-end 
          items-center 
          transition-all 
          duration-300 
          ease-in-out 
          z-[100] 
          border-r 
          border-t 
          border-b 
          
          left-0
          top-[18px]
          bg-background;
}
.pullButtonLeft:hover {
  @apply w-[50px];
}
.pullButtonRight {
  @apply absolute 
          cursor-pointer 
          p-[0.25rem]
          rounded-tl-xl 
          rounded-bl-xl 
          w-[40px]
          flex 
          justify-start 
          items-center 
          transition-all 
          duration-300 
          ease-in-out 
          z-[100] 
          border-l 
          border-t 
          border-b 
          
          right-0
          top-[18px]
          bg-background;
}
.pullButtonRight:hover {
  @apply w-[50px];
}
/* ZIndex order: popover -> modal -> toast */
.Toastify div {
  z-index: 99999999;
}

/* 🚦 disable the toastify base styles */
.Toastify__toast {
  padding: 0 !important;
  box-shadow: none !important;
  background: transparent !important;
  border: none !important;
  margin: 0 !important;
}
.Toastify__toast-icon {
  display: none !important;
}
