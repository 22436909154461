/* TODO #1 - convert to tailwind */
/* TODO #2 - pull out toolbar height, padding/margins etc required for calculating positions into variables. */

/* editor container override */

.editor-container-fp {
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin: 0 auto;
}

.editor-inner-fp {
  position: relative;
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  padding: 2rem;
  border: 1px solid #e2e8f0;
  border-radius: 6px;
  margin: 0 auto;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
  width: 95vw;
}
@media (min-width: 768px){
  .editor-inner-fp {
      width: 85vw;
  }
}

.editor-input-fp {
  background: #fff;
  resize: none;
  height: 100%;
  font-size: 15px;
  caret-color: rgb(5, 5, 5);
  position: relative;
  tab-size: 1;
  outline: 0;
  caret-color: #444;
  flex-grow: 1;
  overflow: hidden;
}

.editor-placeholder-fp {
  color: #999;
  overflow: hidden;
  top: 33px;
  position: absolute;
  text-overflow: ellipsis;
  font-size: 15px;
  user-select: none;
  display: inline-block;
  pointer-events: none;
}

/* toolbar overrides */
/* TODO - find a better way to do such overrides with copying bulk of the css */

.toolbar-fp button.toolbar-item {
  border: 0;
  display: flex;
  background: none;
  border-radius: 10px;
  padding: 8px;
  cursor: pointer;
  vertical-align: middle;
}

.toolbar-fp button.toolbar-item:disabled {
  cursor: not-allowed;
}

.toolbar-fp button.toolbar-item.spaced {
  margin-right: 2px;
}

.toolbar-fp button.toolbar-item i.format {
  background-size: contain;
  display: inline-block;
  height: 18px;
  width: 18px;
  margin-top: 2px;
  vertical-align: -0.25em;
  display: flex;
  opacity: 0.6;
}

.toolbar-fp button.toolbar-item:disabled i.format {
  opacity: 0.2;
}

.toolbar-fp button.toolbar-item.active {
  background-color: rgba(223, 232, 250, 0.3);
}

.toolbar-fp button.toolbar-item.active i {
  opacity: 1;
}

.toolbar-fp .toolbar-item:hover:not([disabled]) {
  background-color: #eee;
}

.toolbar-fp .divider {
  width: 1px;
  background-color: #eee;
  margin: 0 4px;
}

.toolbar-fp select.toolbar-item {
  border: 0;
  display: flex;
  background: none;
  border-radius: 10px;
  padding: 8px;
  vertical-align: middle;
  -webkit-appearance: none;
  -moz-appearance: none;
  width: 70px;
  font-size: 14px;
  color: #777;
  text-overflow: ellipsis;
}

.toolbar-fp select.code-language {
  text-transform: capitalize;
  width: 130px;
}

.toolbar-fp .toolbar-item .text {
  display: flex;
  line-height: 20px;
  width: 200px;
  vertical-align: middle;
  font-size: 14px;
  color: #777;
  text-overflow: ellipsis;
  width: 70px;
  overflow: hidden;
  height: 20px;
  text-align: left;
}

.toolbar-fp .toolbar-item .icon {
  display: flex;
  width: 20px;
  height: 20px;
  user-select: none;
  margin-right: 8px;
  line-height: 16px;
  background-size: contain;
}

.toolbar-fp i.chevron-down {
  margin-top: 3px;
  width: 16px;
  height: 16px;
  display: flex;
  user-select: none;
}

.toolbar-fp i.chevron-down.inside {
  width: 16px;
  height: 16px;
  display: flex;
  margin-left: -25px;
  margin-top: 11px;
  margin-right: 10px;
  pointer-events: none;
}

.floating-text-format-popup {
  display: flex;
  background: #fff;
  padding: 4px;
  vertical-align: middle;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 10;
  opacity: 0;
  background-color: #fff;
  box-shadow: 0px 0px 20px -3px rgba(0, 0, 0, 0.25);
  border-radius: 6px;
  transition: opacity 0.5s;
  /* height: 35px; */
  will-change: transform;
}
